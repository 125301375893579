<template>
  <div>
    <b-modal
      id="modal-primary"
      v-model="dialogOptionLoadingFile"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Disagree')"
      :ok-title="$t('Agree')"
      modal-class="modal-primary"
      centered
      :title="$t('Already had structure {resource}', { resource: $t('Partner') })"
      @ok="chooseOptionLoadFile"
      @cancel="cancelLoadNewFile"
      @hidden="cancelLoadNewFile"
      @close="cancelLoadNewFile"
    >
      <b-card-text>
        <div>
          {{ $t('Already had structure {resource}', { resource: $t('partner') }) }}
        </div>
        <div class="d-flex flex-row align-items-center mt-1">
          <span class="text--primary" style="white-space: nowrap">
            {{ $t('You want') }}:&nbsp;
          </span>
          <base-select
            v-model="loadingFileSelected"
            :options="loadingFileOptions"
            :reduce="(item) => item.value"
            label="text"
            :clearable="false"
            class="w-100"
          />
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-primary"
      v-model="dialogUnfinishedPartner"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Disagree')"
      :ok-title="$t('Agree')"
      modal-class="modal-primary"
      centered
      :title="$t('Unfinished {resource}', { resource: $t('Partner') })"
      no-close-on-esc
      no-close-on-backdrop
      @ok="openUnfinishedPartner"
      @cancel="discardUnfinishedPartner"
      @hidden="discardUnfinishedPartner"
      @close="discardUnfinishedPartner"
    >
      <b-card-text>
        {{ $t('Open last unfinished {resource}?', { resource: $t('partner') }) }}
      </b-card-text>
    </b-modal>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <validation-observer ref="mappingForm">
          <b-form ref="mappingFormName" @submit.prevent="submitForm">
            <b-row id="name">
              <b-col md="6" xl="4" class="mb-1">
                <b-form-group label="Tên đối tác" label-for="partnerName">
                  <validation-provider #default="{ errors }" name="partnerName" rules="required">
                    <b-form-input id="partnerName" v-model="name" placeholder="Tên đối tác" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" xl="4" class="mb-1">
                <b-form-checkbox
                  :checked="isShowStructure"
                  class="custom-control-primary"
                  @change="handleCheckStructure"
                >
                  Tạo cấu trúc file excel đối tác
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="structureLoading" class="text-center">
              <b-col md="12" xl="12" class="mb-1">
                <b-spinner
                  variant="primary"
                  style="width: 3rem; height: 3rem"
                  class="mr-1"
                  label="Large Spinner"
                />
              </b-col>
            </b-row>
            <template v-else-if="isShowStructure">
              <b-row cols="2" class="mt-2">
                <b-col class="mb-1">
                  <validation-observer ref="formImportFile">
                    <b-form @submit.prevent="handleInputFile">
                      <b-row cols="2">
                        <b-col class="mb-1">
                          <b-form-group label="Chọn file  excel mẫu" label-for="partnerName">
                            <validation-provider #default="{ errors }" name="partnerName">
                              <b-form-file
                                id="fileUpload"
                                v-model="fileUpload"
                                placeholder="Chọn file  excel mẫu"
                                drop-placeholder="Drop file here..."
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col class="mb-1">
                          <b-row>
                            <b-col md="8" class="mb-1">
                              <b-form-group label="Dòng bắt đầu dữ liệu" label-for="partnerName">
                                <base-select
                                  id="header-row-pos"
                                  v-model="startRowSelected"
                                  :options="startRows"
                                  :clearable="false"
                                  label="Dòng bắt đầu dữ liệu"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col v-if="fileUpload && startRowSelected" md="4" class="mb-1 mt-2">
                              <base-button
                                type="submit"
                                variant="primary"
                                block
                                :disabled="fileUploading"
                              >
                                <b-spinner v-if="fileUploading" small />
                                {{ $t('Load {resource}', { resource: $t('file') }) }}
                              </base-button>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </b-col>
              </b-row>

              <!-- Table data mapping -->
              <b-row class="text-center">
                <b-col md="12" xl="12" class="card-title"> Cấu trúc file excel đối tác </b-col>
              </b-row>
              <b-row class="text-center">
                <b-col md="12" xl="12" class="mb-1">
                  <b-table-simple
                    id="table-mapping"
                    ref="refUserListTable"
                    class="position-relative"
                  >
                    <b-thead>
                      <b-tr>
                        <b-th
                          v-for="(header, i) in tableHead"
                          :key="i"
                          :style="`
                             width: ${header.width}%;
                            text-align: ${header.align} !important;
                          `"
                        >
                          {{ header.text }}</b-th
                        >
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <template v-if="mappingLoading">
                        <b-tr>
                          <b-td colspan="100">
                            <b-spinner
                              variant="primary"
                              style="width: 3rem; height: 3rem"
                              class="mr-1"
                              label="Large Spinner"
                            />
                          </b-td>
                        </b-tr>
                      </template>
                      <template v-else>
                        <b-tr v-for="(aMap, i) in mapping" :key="aMap.index">
                          <b-td style="padding-left: 20px !important">
                            {{ i + 1 }}
                          </b-td>
                          <b-td class="text-left">
                            <validation-provider
                              v-slot="{ errors }"
                              mode="eager"
                              rules="requiredWithoutName"
                            >
                              <b-form-input v-model="aMap.header" size="small" />
                              <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-td>
                          <b-td>
                            <base-select
                              v-model="aMap.type"
                              :name="`mapping:${i}:type`"
                              :options="types"
                              :reduce="(item) => item.value"
                              label="text"
                              :clearable="false"
                            />
                          </b-td>
                          <b-td>
                            <base-select
                              :name="`mapping:${i}:table`"
                              :options="tableNamesItems"
                              :reduce="(item) => item.value"
                              :value="aMap.table"
                              label="text"
                              :clearable="false"
                              @input="handleChangeTable($event, i)"
                            />
                          </b-td>
                          <b-td class="text-left">
                            <!-- <select-modal
                              v-if="columns[i] && columns[i].length > 0"
                              :options="columns[i]"
                              :value="aMap.column"
                              :prefix-trans="mapping[i].table"
                              :title="
                                $t('Please chose column from {resource}', {
                                  resource: $t(mapping[i].table),
                                })
                              "
                              @selected="handleChangeColumn($event, i)"
                            /> -->
                            <b-form-input
                              v-if="columns[i] && columns[i].length > 0"
                              :value="columns[i].find((col) => aMap.column === col.value).text"
                              readonly
                              class="cursor-pointer"
                              @click="handleClickColumn(columns[i], i)"
                            />
                            <validation-provider
                              v-else
                              v-slot="{ errors }"
                              mode="eager"
                              :vid="`mapping.${i}.label`"
                              rules="requiredWithoutName"
                            >
                              <b-form-input v-model="aMap.label" placeholder="Tên đối tác" />
                              <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-td>
                          <b-td>
                            <b-form-checkbox
                              v-if="aMap.require === -1"
                              :name="`mapping:${i}:require`"
                              :checked="aMap.require"
                              :value="1"
                              :unchecked-value="0"
                              class="custom-control-primary d-inline-block"
                            />
                            <b-form-checkbox
                              v-else
                              :name="`mapping:${i}:require`"
                              :checked="aMap.require"
                              :value="1"
                              :unchecked-value="0"
                              class="custom-control-primary d-inline-block"
                            />
                          </b-td>
                          <b-td>
                            <base-button
                              variant="flat-danger"
                              class="btn-icon rounded-circle"
                              @click="handleClickDeleteRow(i)"
                            >
                              <feather-icon size="20" icon="XIcon" />
                            </base-button>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td colspan="100">
                            <base-button
                              id="add-new-row"
                              variant="outline-primary"
                              @click="handleAddNewRow"
                            >
                              <feather-icon icon="PlusCircleIcon" class="mr-50" />
                              <span class="align-middle"> {{ $t('Add new row') }}</span>
                            </base-button>
                          </b-td>
                        </b-tr>
                      </template>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-row class="text-right">
                <b-col md="12" xl="12" class="mb-1">
                  <loader-button
                    type="submit"
                    variant="primary"
                    spiner-style="width: 1rem; height: 1rem"
                    spiner-variant="light"
                    :loading="loading"
                  >
                    {{ $t('Update') }}
                  </loader-button>
                </b-col>
              </b-row>
            </template>
          </b-form>
        </validation-observer>
      </div>
    </b-card>

    <base-modal-search
      :options="modalOptions"
      :value="modalSelected"
      :title="$t('Please chose column')"
      @selected="handleChangeColumn($event)"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardText,
  BFormCheckbox,
  BSpinner,
  BFormFile,
  BThead,
  BTableSimple,
  BTbody,
} from 'bootstrap-vue';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import { numberWithCommas } from '@core/utils/utils';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import constRouter from '@/constants/constRouter';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import { BTd, BTh, BTr } from 'bootstrap-vue/src/components/table';
import {
  destroyStorePartnerToken,
  getStorePartnerToken,
  setStorePartnerToken,
} from '@/common/localStorage';
import { structure, types } from '@/apis/general';
import { partnerCreate, partnerUpdate, showPartner } from '@/apis/apiPartner';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import SelectModal from '@/components/base/SelectModal.vue';
import BaseModalSearch from '@/components/base/BaseModalSearch.vue';
import LoaderButton from '@/components/base/LoaderButton.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    BaseButton,
    BaseSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BFormFile,
    BSpinner,
    BButton,
    BTable,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardText,
    LoaderButton,
    SelectModal,
    ValidationProvider,
    ValidationObserver,
    BaseModalSearch,
  },

  data: () => ({
    constRouter,
    required,

    tableHead: [
      {
        text: 'STT',
        width: 1,
      },
      {
        text: 'Tên cột excel',
      },
      {
        text: 'Định dạng',
        width: 14,
      },
      {
        text: 'Đối tượng',
        width: 14,
      },
      {
        text: 'Thuộc tính/ tên gọi khác',
      },
      {
        text: 'Bắt buộc',
        align: 'center',
      },
      {
        text: 'Xóa',
        align: 'center',
      },
    ],

    partnerId: undefined,
    isShowStructure: true,
    workbook: undefined,
    newHeaders: undefined,
    file: null,
    fileUpload: undefined,
    fileUploading: false,
    startRows: [2, 3, 4, 5],
    startRowSelected: 2,
    header: [],
    columnsLoading: [],
    columns: [],
    name: undefined,
    code: undefined,
    types: [],
    errOfColumns: [],
    hasErr: false,
    mappingFieldStructure: {},
    tableNames: [],
    tableNamesItems: [],
    selectedTable: '',
    table: [],
    mapping: [],
    mappingTemp: [],
    columnsTemp: [],
    loading: false,
    structureLoading: false,
    mappingLoading: false,
    showPartnerLoading: false,
    formSubmitting: false,
    // debounce search
    debounce: null,
    // dialog
    dialogUnfinishedPartner: false,
    dialogGuideStartRow: false,
    dialogGuideInputFile: false,
    unfinishedPartner: undefined,
    isDuplicatePartner: false,

    // option load file
    dialogOptionLoadingFile: false,
    dialogChoseColumns: false,
    checkedColumns: [],
    checkedAllColumn: false,
    loadingFileOptions: [
      {
        value: 'prepend',
        text: 'Giữ nguyên cấu trúc cũ, chèn thêm cột mới',
      },
      {
        value: 'override',
        text: 'Xóa cấu trúc cũ, tạo mới cấu trúc',
      },
    ],
    loadingFileSelected: 'prepend',
    modalSelected: null,
    modalOptions: [],
    modalRowEdit: -1,
  }),

  watch: {
    checkedAllColumn(isChecked) {
      if (isChecked) {
        this.checkedColumns = this.newHeaders;
      } else if (this.is2ArrEqual(this.checkedColumns, this.newHeaders)) {
        // if 2 array is equal => clear selected
        this.checkedColumns = [];
      }
    },
    checkedColumns(newArr) {
      // check all column
      this.checkedAllColumn = !!this.is2ArrEqual(newArr, this.newHeaders);
    },
  },
  async mounted() {
    // call api show and structure
    this.showPartner();
    // call api data type
    this.getDataType();
  },

  beforeDestroy() {
    clearTimeout(this.debounce);
  },

  methods: {
    handleClickColumn(options, index) {
      this.modalOptions = options;
      this.modalRowEdit = index;

      // selected column modal
      this.$bvModal.show('select-modal');
    },
    scrollTo(
      element,
      options = {
        offset: -500,
        duration: 5000,
      },
    ) {
      setTimeout(() => {
        this.$scrollTo(element, options);
      }, 200);
    },

    handleCheckStructure(check) {
      if (check) {
        if (!(this.mapping && this.mapping.length > 0)) {
          this.columns[0] = this.tableNames[0].listCol;
          this.mapping = [
            {
              header: undefined,
              label: undefined,
              table: this.tableNames[0].value,
              column: this.columns[0][0].id,
              type: 'text',
              require: 0,
            },
          ];
        }
        // show child element
        this.isShowStructure = true;
      } else {
        this.isShowStructure = false;
      }
    },

    handleChangeHeader() {},

    handleChangeLabel() {},

    handleChangeTable(tableName, row) {
      this.mapping[row].table = tableName;
      this.testPerform = Date.now();
      const column = this.findColumn(tableName);
      // const random = Math.floor(Math.random() * 2) + 1;
      // console.log(random);
      // const column = this.tableNames[random].listCol;
      setTimeout(() => {
        this.columns.splice(row, 1, column);
        if (column && column.length > 0) {
          this.$set(this.mapping[row], 'column', column[0].id);
        } else {
          this.$set(this.mapping[row], 'column', undefined);
        }
      });
      this.errOfColumns[row] = undefined;
    },

    handleChangeColumn(colName) {
      this.mapping[this.modalRowEdit].column = colName;
    },

    handleChangeRequired(check, i) {
      if (check) {
        if (check === -1) {
          this.mapping[i].require = 0;
        } else {
          this.mapping[i].require = 1;
        }
      } else {
        this.mapping[i].require = 0;
      }
    },

    handleClickDeleteRow(i) {
      this.mapping.splice(i, 1);
      this.header.splice(i, 1);
      this.columns.splice(i, 1);
    },

    async handleInputFile() {
      // turn on loading
      this.fileUploading = true;
      this.mappingLoading = true;
      // check validate
      const result = await this.$refs.formImportFile.validate();
      // valid
      if (result) {
        if (this.fileUpload) {
          // read file
          this.file = this.fileUpload;
          const workbook = await this.getWorkbookFromFile(this.fileUpload);
          if (workbook) {
            this.processDataFromWorkbook(workbook);
          }
        }
      } else {
        // turn on loading
        // this.fileUploading = false;
        // scroll to body mapping
        this.scrollTo('#table-mapping');
      }
    },

    chooseOptionLoadFile() {
      // turn off loading
      this.dialogOptionLoadingFile = false;

      setTimeout(() => {
        this.processDataFromWorkbook(this.workbook);
      }, 500);
    },

    cancelLoadNewFile() {
      // turn on loading;
      this.dialogOptionLoadingFile = false;
      this.dialogChoseColumns = false;
      this.fileUploading = false;
      this.mappingLoading = false;
    },

    chooseCols2Insert() {
      this.dialogChoseColumns = false;
      if (this.newHeaders) {
        const listMapAppend = this.checkedColumns.map(
          (itemCheck) => this.mappingTemp.filter((aMap) => aMap.header === itemCheck)[0],
        );
        this.insertNewCols(listMapAppend);

        // scroll to body mapping
        this.scrollTo('#table-mapping');
      }
    },

    getWorkbookFromFile(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        let workbook;
        reader.onload = (e) => {
          const data = e.target.result;
          workbook = this.$xlsx.read(data, { type: 'binary' });
          resolve(workbook);
        };
      });
    },

    handleAddNewRow() {
      const index = this.mapping.length;
      this.columns[index] = this.tableNames[0].listCol;
      this.mapping.push({
        header: undefined,
        label: undefined,
        table: 'customers',
        column: this.columns[index][0].id,
        type: 'text',
        require: 0,
        index: uuidv4(),
      });

      // scroll down
      this.scrollTo('#add-new-row', {
        offset: -100,
        duration: 5000,
      });
    },

    findColumn(tableName) {
      // edit
      const loc = this.tableNames.findIndex((item) => item.value === tableName);
      if (loc !== -1) {
        return this.tableNames[loc].listCol;
      }
      return undefined;
    },

    // api data type
    getDataType() {
      // Api to get types
      types()
        .then((res) => {
          // process data type for ui
          this.types = this.processDataTypes4UI(res.data);
        })
        .catch(() => {});
    },

    processDataTypes4UI(oldTypes) {
      const typeList = [];
      oldTypes.forEach((type) => {
        const typeText = this.$t(type);
        switch (type) {
          case 'text': {
            typeList.push({
              value: type,
              text: typeText,
              top: 1,
            });
            break;
          }
          case 'numeric': {
            typeList.push({
              value: type,
              text: typeText,
              top: 2,
            });
            break;
          }
          case 'date': {
            typeList.push({
              value: type,
              text: typeText,
              top: 3,
            });
            break;
          }
          case 'phone': {
            typeList.push({
              value: type,
              text: typeText,
              top: 4,
            });
            break;
          }
          case 'address': {
            typeList.push({
              value: type,
              text: typeText,
              top: 5,
            });
            break;
          }
          default: {
            typeList.push({
              value: type,
              text: typeText,
              top: 9999,
            });
            break;
          }
        }
      });
      // Todo
      // typeList = Utils.sortArObject(typeList, 'top');
      return typeList;
    },

    showPartner() {
      const id = +this.$route.params.id;
      if (!Number.isNaN(id)) {
        this.partnerId = id;
        // call api structure
        const callStructure = structure();
        // call api show partner
        const callPartner = showPartner(id);

        Promise.all([callPartner, callStructure])
          .then((values) => {
            const thePartnerRes = values[0];
            const structureRes = values[1];
            const mapping = thePartnerRes.data.data?.mapping;
            // this.fileLoadingBtn = false;

            // partner
            this.processPartnerDataBasic4UI(thePartnerRes.data.data);

            // structure
            this.processStructureData4UI(structureRes.data);

            if (mapping) {
              // show mapping
              this.isShowStructure = true;
              this.mappingLoading = true;

              // set data for columns in mapping
              const columns = [];
              mapping.forEach((item, index) => {
                columns[index] = this.findColumn(item.table);
              });
              // this.columns = Object.freeze(columns);
              this.columns = columns;
              // console.log(columns);
              // turn off loading
              this.structureLoading = false;
            } else {
              // turn off loading
              this.structureLoading = false;
            }
            // set mapping data
            setTimeout(() => {
              this.setPartnerMapping(mapping);
              this.mappingLoading = false;
            }, 500);
          })
          .catch(() => {
            // turn off loading
            this.showPartnerLoading = false;
            this.structureLoading = false;
          });
      }
    },

    discardUnfinishedPartner() {
      // turn off model
      this.dialogUnfinishedPartner = false;
      // turn off loading
      this.showPartnerLoading = false;
      this.structureLoading = false;
      this.mappingLoading = false;

      // clear partner in local storage
      this.unfinishedPartner = undefined;
      destroyStorePartnerToken();
    },

    openUnfinishedPartner() {
      // turn off model
      this.dialogUnfinishedPartner = false;
      // set data unfinished partner
      this.processPartnerDataBasic4UI(this.unfinishedPartner);
      let { mapping } = this.unfinishedPartner;
      if (mapping) {
        // show mapping
        this.isShowStructure = true;
        // set data for columns in mapping
        mapping.forEach((item, index) => {
          this.columns[index] = this.findColumn(item.table);
        });
        // turn off mappingLoading
        this.structureLoading = false;
      } else {
        // turn off mappingLoading
        this.mappingLoading = false;
      }
      // set mapping data
      setTimeout(() => {
        mapping = mapping.map((item) => {
          if (Number.isInteger(+item.require)) {
            return {
              ...item,
              require: +item.require,
            };
          }
          return {
            ...item,
          };
        });
        this.setPartnerMapping(mapping);
        this.mappingLoading = false;
      }, 500);
    },

    processPartnerDataBasic4UI(data) {
      // turn off showPartnerLoading
      this.showPartnerLoading = false;
      if (data) {
        this.name = data.name;
        this.code = data.code;
        this.startRowSelected = data.start_row;
      }
    },

    setPartnerMapping(mapping) {
      if (mapping) {
        this.mapping = mapping;
      } else {
        this.mapping = [];
      }
      this.mapping = this.mapping.map((item) => {
        if (!item.index) {
          return { ...item, index: uuidv4() };
        }
        return item;
      });
    },

    overrideOldFile(headerRow, dataTypes, requires) {
      this.mapping = [];
      headerRow.forEach((header, index) => {
        this.columns[index] = this.tableNames[0].listCol;
        this.mapping.push({
          colExcel: header.colExcel,
          header: header.header,
          label: header.header,
          table: 'customers',
          column: this.columns[index][0].id,
          type: dataTypes[index].type || 'text',
          require: requires[index].require || 0,
        });
      });
    },

    showDialogChoseColumns(headerRow) {
      const currentHeaderRow = this.mapping.map((aMap) => aMap.header);
      const loadedHeaderRow = headerRow.map((aMap) => aMap.header);
      this.newHeaders = this.findNewElements(currentHeaderRow, loadedHeaderRow);
      // auto check all columns
      this.checkedAllColumn = true;
      this.checkedColumns = this.newHeaders;
      // show dialog
      this.dialogChoseColumns = true;
    },

    insertNewCols(listMapAppend) {
      listMapAppend.forEach((aMap) => {
        this.columns.unshift(this.tableNames[0].listCol);
        this.mapping.unshift(aMap);
      });
    },

    processDataFromWorkbook(workbook) {
      this.fileUploading = false;
      this.mappingLoading = false;

      const sheets = workbook.SheetNames;
      const firstSheet = workbook.Sheets[sheets[0]];
      const locHeaderRow = 1;

      const headerRow = this.getHeaderRow(firstSheet, locHeaderRow);

      const locStartRow = this.startRowSelected;
      const dataTypes = this.dataTypeOfCols(firstSheet, headerRow, locStartRow);
      const rowLength = this.$xlsx.utils.sheet_to_json(firstSheet).length;
      const requires = this.requiredOfCols(firstSheet, headerRow, locStartRow, rowLength);

      this.mappingTemp = [];
      this.columnsTemp = [];
      headerRow.forEach((header, index) => {
        this.columnsTemp[index] = this.tableNames[0].listCol;
        this.mappingTemp.push({
          colExcel: header.colExcel,
          header: header.header,
          label: header.header,
          table: 'customers',
          column: this.columnsTemp[index][0].id,
          type: dataTypes[index].type || 'text',
          require: requires[index].require || 0,
        });
      });

      // turn of loading
      this.fileUploading = false;
      this.mappingLoading = false;
    },

    submitForm() {
      // turn on loading
      this.loading = true;
      setTimeout(() => {
        this.validateAndCallApiSubmit();
      }, 0);
    },

    async validateAndCallApiSubmit() {
      const mappingFormObserver = this.$refs.mappingForm;
      return mappingFormObserver.validate().then((isValid) => {
        if (isValid) {
          // valid
          // list err of fields
          const errAvailable = this.errOfColumns.filter((item) => item !== undefined);

          if (errAvailable.length <= 0) {
            // get data type, table, column
            const { name, code, startRowSelected: startRow } = this;
            let { mapping } = this;
            mapping = mapping.map((item, index) => ({
              ...item,
            }));
            const dataSend = this.processData4API(name, code, mapping, startRow);
            partnerUpdate(this.partnerId, dataSend)
              .then((res) => {
                // turn of loading
                this.loading = false;

                this.$router.push({
                  name: 'partners.show',
                  params: { id: res.data.data?.id },
                });

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('Update {resource}', {
                      resource: this.$t('partner'),
                    }),
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: this.$t('Update {resource} successfully!', {
                      resource: this.$t('partner'),
                    }),
                  },
                });
              })
              .catch((err) => {
                // turn of loading
                this.loading = false;

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('Update {resource}', {
                      resource: this.$t('partner'),
                    }),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: this.$t('Update {resource} failed!', {
                      resource: this.$t('partner'),
                    }),
                  },
                });
                // set err
                if (err && err.data.errors) {
                  // set errors from api for vee validate errors
                  mappingFormObserver.setErrors(err.data.errors);
                  this.scrollToError(err.data.errors, mappingFormObserver);
                }
              });
          } else {
            // turn of loading
            this.loading = false;
          }
        } else {
          // turn of loading
          this.loading = false;
          // console.log('this.$refs.errors.errors', typeof this.$refs.mappingForm.errors);
          this.scrollToError(mappingFormObserver.errors, mappingFormObserver);
        }
      });
    },

    scrollToError(listErrors, observer) {
      const errors = Object.entries(listErrors)
        .map(([key, value]) => ({
          key,
          value,
        }))
        .filter((error) => error.value.length)
        .map(({ key }) => key);
      if (errors && errors.length > 0) {
        this.scrollTo(observer.refs[errors[0]].$el);
      }
    },

    getMappingNameForm() {
      const mapLength = this.mapping.length;
      const myForm = this.$refs.mappingFormName;
      const formData = new FormData(myForm);
      const array = Array.from(formData);
      const object = array.map((item) => {
        const key = item[0];
        const value = item[1];
        return {
          [key]: value,
        };
      });

      const mapping = [];
      for (let i = 0; i < mapLength; i += 1) {
        mapping[i] = {};
        for (let j = 0; j < object.length; j += 1) {
          const item = object[j];
          if (item[`mapping:${i}:type`]) {
            mapping[i].type = item[`mapping:${i}:type`];
          }
          if (item[`mapping:${i}:column`]) {
            mapping[i].column = item[`mapping:${i}:column`];
          }
          if (item[`mapping:${i}:table`]) {
            mapping[i].table = item[`mapping:${i}:table`];
          }
          if (item[`mapping:${i}:require`]) {
            mapping[i].require = item[`mapping:${i}:require`];
          }
        }
      }

      return mapping;
    },

    processData4API(name, code, mapping, startRow) {
      let dataSend;
      if (this.isShowStructure) {
        // add sub field
        const mappingSend = this.addSubField2API(mapping);
        dataSend = {
          name,
          code,
          startRow,
          mapping: mappingSend,
        };
      } else {
        dataSend = {
          name,
          code,
          startRow,
        };
      }
      return dataSend;
    },

    addSubField2API(mapping) {
      const data = mapping;
      // loop arr mapping, each element compare to the structure
      data.forEach((item, index) => {
        // => { customers: [ "code", "name", "birthday", "phone", "address"] }
        const object = this.mappingFieldStructure.find(
          (tableSelected) => Object.keys(tableSelected)[0] === item.table,
        );
        // if values array of temp <= 0 => sub
        if (this.isSub(object)) {
          data[index].sub = 1;
          delete data[index].column;
        } else {
          // sub = 0, means has column
          data[index].sub = 0;
        }

        // if label = undefined, label = header
        if (!data[index].label) {
          data[index].label = data[index].header;
        }
      });
      return data;
    },

    // check which table name is sub?
    isSub(table) {
      // if undefined
      if (!table) {
        return true;
      }
      // if values of table <= 0: => sub
      return !(Object.values(table)[0] && Object.values(table)[0].length > 0);
    },

    processStructureData4UI(mappingFieldStructure) {
      this.mappingFieldStructure = mappingFieldStructure;
      const tableNames = [];
      for (let i = 0; i < mappingFieldStructure.length; i += 1) {
        const tableValues = Object.values(mappingFieldStructure[i])[0];
        const tableKeys = Object.keys(mappingFieldStructure[i])[0];
        const cols = [];

        if (tableValues) {
          tableValues.forEach((tableValue) => {
            let colText = this.$t(`${tableKeys}.${tableValue}`);
            if (colText.includes('.')) {
              colText = tableValue;
            }
            // col = { text, value }
            cols.push({
              text: colText,
              value: tableValue,
              id: tableValue,
            });
          });
        }
        // Todo
        // cols = Object.freeze(Utils.sortArObject(cols, 'text'));
        // col = { tableText, tableValue, listCol }
        this.tableNamesItems.push({
          text: this.$t(tableKeys),
          value: tableKeys,
          id: tableKeys,
        });
        tableNames.push({
          text: this.$t(tableKeys),
          id: tableKeys,
          value: tableKeys,
          listCol: cols,
        });
      }
      this.tableNames = Object.freeze(tableNames);
    },

    // => [{ colExcel: "I", header: "Họ tên"}...]
    getHeaderRow(sheet, positionHeaderRow) {
      // key: ["A2", "B2", "C2",...]
      const keys = Object.keys(sheet)
        .filter((item) => item !== '!margins' && item !== '!ref')
        .filter((item) => {
          // get number from text A1 => temp: ['1']
          const temp = item.match(/\d+/);
          if (Array.isArray(temp)) {
            return +temp.join('') === +positionHeaderRow;
          }
          return false;
        });
      return keys.map((elem) => ({
        colExcel: elem.replace(/[0-9]/g, ''),
        header: sheet[elem].v,
      }));
    },

    dataTypeOfCols(sheet, headerRow, locStartRow) {
      const colTypeList = [];
      headerRow.forEach((header) => {
        const columnName = header.colExcel;
        // get list key of 1 column
        const listKeyOfACol = Object.keys(sheet).filter((item) => {
          // item null was filtered by library
          const columnNameTemp = item.replace(/[0-9]/g, '');
          return columnNameTemp === columnName;
        });

        const listDataOfACol = listKeyOfACol
          // remove cell header to before
          .filter((item) => {
            const temp = item.match(/\d+/);
            return +temp.join('') >= locStartRow;
          })
          .map((item) => sheet[item]);
        // push new object
        colTypeList.push({
          col: columnName,
          type: this.dataTypeOfCol(listDataOfACol),
        });
      });
      return colTypeList;
    },

    dataTypeOfCol(listCell) {
      if (listCell.length > 0) {
        const temps = listCell.filter((cell) => cell.t === 'n');
        if (listCell.length === temps.length) {
          for (let i = 0; i < listCell.length; i += 1) {
            const cell = listCell[i];
            if (cell.w.includes('/')) {
              return 'date';
            }
          }
          return 'numeric';
        }
        let length = 0;
        for (let i = 0; i < listCell.length; i += 1) {
          const cell = listCell[i];
          const cellValue = cell.v.toString().replace("'", '');
          if (Number.isInteger(+cellValue)) {
            length += 1;
          }
          if (listCell[i].w.includes('/')) {
            const cellTemp = cellValue.replaceAll('/', '');
            if (Number.isInteger(+cellTemp)) {
              return 'date';
            }
          }
        }
        if (listCell.length === length) {
          return 'numeric';
        }
      }
      return 'text';
    },

    requiredOfCols(sheet, headerRow, locStartRow, rowLength) {
      const colRequiredList = [];
      headerRow.forEach((header) => {
        const columnName = header.colExcel;
        // get list key of 1 column
        const listKeyOfACol = Object.keys(sheet).filter((item) => {
          // item null was filtered by library
          const columnNameTemp = item.replace(/[0-9]/g, '');
          return columnNameTemp === columnName;
        });

        const listDataOfACol = listKeyOfACol
          // remove cell header
          .filter((item) => {
            const temp = item.match(/\d+/);
            return +temp.join('') >= locStartRow;
          })
          .map((item) => sheet[item]);
        // push new object
        colRequiredList.push({
          col: columnName,
          require: this.isRequiredColumn(listDataOfACol, locStartRow, rowLength),
        });
      });
      return colRequiredList;
    },

    isRequiredColumn(listCell, locStartRow, rowLength) {
      if (listCell && listCell.length > 0) {
        const temps = listCell.filter((cell) => cell.v !== '');
        if (rowLength - locStartRow + 2 === temps.length) {
          return 1;
        }
        return -1;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
