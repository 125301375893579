<template>
  <div class="text-center">
    <!-- <b-form-input
      v-model="currentText"
      :placeholder="$t('Please chose')"
      readonly
      class="cursor-pointer"
      @click="showModal"
    /> -->
    <b-modal
      v-if="options"
      id="select-modal"
      ref="select-modal"
      size="xl"
      hide-footer
      :title="title"
    >
      <div class="w-100 px-2 mt-1 mb-2">
        <b-form-input v-model="search" :placeholder="$t('Search')" />
      </div>
      <div
        v-if="showOptions && showOptions.length"
        class="d-flex flex-wrap justify-content-sm-center p-1"
        style="row-gap: 3rem; column-gap: 3rem"
      >
        <b-badge
          v-for="(option, index) in showOptions && showOptions"
          :key="index"
          class="d-flex justify-content-center align-items-center font-small-4"
          style="
            padding: 1rem;
            width: 180px;
            height: 50px;
            white-space: break-spaces;
            line-height: 18px;
          "
          role="button"
          variant="primary"
          @click="select(option)"
        >
          <span>{{ option.text }}</span>
        </b-badge>
      </div>
      <div v-else class="mb-2">
        <b-alert show variant="danger">
          <div class="alert-body">
            <feather-icon class="mr-25" icon="AlertOctagonIcon" />
            <span class="ml-25">
              {{ $t('No result') }}
            </span>
          </div>
        </b-alert>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BAlert, BBadge, BFormInput, BModal } from 'bootstrap-vue';
import { removeViTones } from '@core/utils/utils';

export default {
  name: 'BaseModalSearch',
  components: {
    BAlert,
    BBadge,
    BFormInput,
    BModal,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: 'Default',
    },
    prefixTrans: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    currentOptionSelected: null,
    data: null,
    search: null,
  }),
  computed: {
    currentSelected() {
      return this.currentOptionSelected?.value || null;
    },
    currentText() {
      return this.currentOptionSelected?.text || null;
    },
    showOptions() {
      if (this.search) {
        return this.options.filter(
          (o) =>
            removeViTones(o.text.toLowerCase()).indexOf(removeViTones(this.search.toLowerCase())) >
            -1,
        );
      }
      return this.options;
    },
  },
  watch: {
    options() {
      this.setCurrent();
    },
    search() {
      if (this.search) {
        this.showOptions = this.options.filter(
          (o) =>
            removeViTones(o.text.toLowerCase()).indexOf(removeViTones(this.search.toLowerCase())) >
            -1,
        );
      } else {
        this.showOptions = this.options;
      }
    },
  },
  created() {
    this.setCurrent();
    this.sortOptions();
  },
  methods: {
    sortOptions() {
      this.options.sort((a, b) => {
        const nameA = a.text.toUpperCase(); // ignore upper and lowercase
        const nameB = b.text.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    },
    setCurrent() {
      // console.log(this.value);
      this.currentOptionSelected = this.options.find((opt) => opt.value === this.value);
    },
    showModal() {
      this.$refs['select-modal'].show();
    },
    select(option) {
      this.currentOptionSelected = option;
      this.$emit('selected', this.currentSelected);
      this.$refs['select-modal'].hide();
    },
  },
};
</script>
